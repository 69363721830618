<template>
  <v-card :loading="isLoading">
    <v-card-text v-if="clusterId">
      <v-data-table
        v-if="clusterId"
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Arıza Tür Tanımları"
            icon="mdi-toolbox-outline"
            :add-route="
              can('edit-breakdown-type')
                ? {
                    name: 'other-definitions.types.breakdown-types.create',
                  }
                : null
            "
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :hide-edit="!can('edit-breakdown-type')"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            @click:edit="handleEditClick"
            :show-delete="can('delete-breakdown-type')"
            :delete-enabled="selectedItems.length === 1"
            @click:delete="handleBatchDeleteClick"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'other-definitions.types.breakdown-types.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:-item.name="{ item }">
          <router-link
            :to="{
              name: 'other-definitions.types.breakdown-types.show',
              params: { id: item.id },
            }"
          >
            {{ item.name }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_active="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
          <rs-table-button-delete
            @deleted="handleDelete(item)"
            :loading="isLoading"
          />
        </template>
      </v-data-table>
      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDeleteConfirmed"
        @cancelled="$refs.confirmDelete.hide()"
      />
    </v-card-text>
    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasPermissions } from "@/view/mixins";

export default {
  mixins: [filtersToURL, hasPermissions],
  computed: {
    ...mapGetters(["clusterId"]),
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }, 250),
      deep: true,
    },
    clusterId: {
      handler() {
        this.loadList();
      },
    },
  },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-breakdown-type"),
        },
        {
          text: "Arıza Türü",
          value: "name",
          searchable: "text",
        },
        {
          text: this.$t("labels.status"),
          value: "is_active",
          searchable: "isActive",
          align: "center",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.selectedItems = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.cluster_id = this.clusterId;

      this.$api
        .query(`/clusters/${this.clusterId}/breakdown-types/`, {
          params,
        })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length === 1) {
        this.$router.push({
          name: "other-definitions.types.breakdown-types.edit",
          params: { id: this.selectedItems[0].id },
        });
      }
    },
    handleDelete(item) {
      this.isLoading = true;
      item;

      this.$api
        .delete(`breakdown-types/${this.selectedItems[0].id}`)
        .then(() => {
          this.$nextTick(() => this.loadList());
        })
        .catch((error) => {
          this.handleError(error);
        });

      setTimeout(() => {
        this.isLoading = false;
        this.loadList();
        this.$toast.success(this.$t("toasts.item_deleted"));
      }, 1500);
    },
    handleBatchDeleteClick() {
      this.$refs.confirmDelete.show(
        `${this.selectedItems[0].name} arıza türünü silmek istediğinizden emin misiniz?`
      );
    },
    handleDeleteConfirmed() {
      for (let index in this.selectedItems) {
        this.handleDelete(this.selectedItems[index]);
      }
      this.$refs.confirmDelete.hide();
    },
  },
};
</script>
